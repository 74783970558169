import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import CAA from "../../assets/Landing/logo-caa.svg";
import FAA from "../../assets/Landing/logo-faa.svg";
import EASA from "../../assets/Landing/logo-easa.svg";
import TC from "../../assets/Landing/logo-transport-canada.svg";
import GCAA from "../../assets/Landing/logo-gcaa.png";
import SanMarino from "../../assets/Landing/san-marino2x.png";
import Aruba from "../../assets/Landing/aruba2x.png";
import Cayman from "../../assets/Landing/cayman2x.png";
import Bermuda from "../../assets/Landing/bermuda2x.png";
import IOM from "../../assets/Landing/iom-caa2x.png";
import DCA from "../../assets/Landing/dca-logo.svg";

const Background = styled.div`
  background-image: ${({ trialPage }) =>
    trialPage ? "white" : "linear-gradient(to top, #f0f5f9, #ffffff 100%)"};
`;

const Header = styled.h2`
  text-align: center;
  margin-bottom: 64px;
  @media screen and (max-width: 991px) {
    font-size: 28px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 576px) {
    font-size: 22px;
    margin-bottom: 40px;
  }
`;

const Padding = styled.div`
  ${(props) =>
    props.trialPage ? "padding-bottom: 110px" : "padding: 160px 120px"};
  display: grid;
  gap: 1rem;
  @media screen and (max-width: 991px) {
    padding: 60px 0;
  }
  @media screen and (max-width: 576px) {
    padding: 40px 0;
    grid-template-columns: 1fr;
  }
`;

const LogosWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "faa tc easa caa gcaa";
  gap: 3rem;
  justify-items: center;
  align-items: center;
  @media screen and (max-width: 576px) {
    gap: 1.5rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "faa faa tc tc easa easa"
      ". caa caa gcaa gcaa .";
  }
`;

// FAA, TCCA, EASA, UKCAA, GCAA

const TrialLogosWrapper = styled.div`
  text-align: center;
  & img {
    height: 48px;
    margin-right: 71px;
    margin-bottom: 3em;
  }
`;

const EASALogo = styled.div`
  grid-area: easa;
`;
const CAALogo = styled.div`
  grid-area: caa;
`;
const TCLogo = styled.div`
  grid-area: tc;
`;
const FAALogo = styled.div`
  grid-area: faa;
`;
const GCAALogo = styled.div`
  grid-area: gcaa;
`;

const Authorities = ({ trialPage }) => (
  <Background trialPage={trialPage}>
    <Container>
      <Padding trialPage={trialPage}>
        <Header>
          {trialPage
            ? "Compliant with"
            : "Our software powers operators approved under many regulatory agencies including"}
        </Header>
        {trialPage ? (
          <TrialLogosWrapper>
            <img src={FAA} alt="logo" />
            <img src={TC} alt="logo" />
            <img src={EASA} alt="logo" />
            <img src={CAA} alt="logo" />
            <img src={IOM} alt="logo" />
            <img src={DCA} alt="logo" />
            <img src={SanMarino} alt="logo" />
            <img src={Bermuda} alt="logo" />
            <img src={Cayman} alt="logo" />
            <img src={Aruba} alt="logo" />
          </TrialLogosWrapper>
        ) : (
          <LogosWrapper>
            <FAALogo>
              <img src={FAA} alt="logo" />
            </FAALogo>
            <TCLogo>
              <img src={TC} alt="logo" />
            </TCLogo>
            <EASALogo>
              <img src={EASA} alt="logo" />
            </EASALogo>
            <CAALogo>
              <img src={CAA} alt="logo" />
            </CAALogo>
            <GCAALogo>
              <img src={GCAA} alt="logo" />
            </GCAALogo>
          </LogosWrapper>
        )}
      </Padding>
    </Container>
  </Background>
);

export default Authorities;
