import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { AnimationOnScroll } from "react-animation-on-scroll";
import GlobalScale from "../components/LandingPage/globalScale";
import MeetTheTeam from "../components/LandingPage/meetTheTeam";
import Authorities from "../components/LandingPage/authorities";
import DisplayOption from "../components/LandingPage/displayOption";
import TrustedBy from "../components/LandingPage/trustedBy";
import Seo from "../components/SEO";
import Started from "../components/Layout/started";
import Layout from "../components/layout";
import PageHeaderVideo from "../components/pageHeaderVideo";
import * as styles from "./index.module.less";

const TransformHeader = styled.h2`
  margin-top: 80px;
  font-weight: 600;
  letter-spacing: 0.04px;
  line-height: 48px;
  text-align: center;
  color: #242d41;
  margin-bottom: 25px;
  @media screen and (max-width: 1200px) {
    font-size: 28px;
    line-height: 36px;
  }
  @media screen and (max-width: 991px) {
    font-size: 28px;
    margin-top: 60px;
    line-height: inherit;
  }
  @media screen and (max-width: 576px) {
    font-size: 22px;
    margin-top: 40px;
    line-height: inherit;
  }
`;

const TabSection = styled.div`
  width: 100%;
  background-image: linear-gradient(to top, #f0f5f9, #ffffff 100%);
  padding-bottom: 60px;
  @media screen and (max-width: 400px) {
    padding: 0;
  }
`;

const ClickableOption = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: rgba(36, 45, 65, 0.7);
  padding: 25px 10px;
  border-bottom: 1px solid #eeeff3;
  cursor: pointer;
  transition: all 0.2s;
  ${(props) => props.selected && "border-bottom: 1px solid #1E5BB5;"}
  ${(props) => props.selected && "color: #1E5BB5;"}
  &:hover {
    color: #1e5bb5;
  }
  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }
  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
    padding: 15px 10px;
  }
  @media screen and (max-width: 576px) {
    font-size: 14px;
  }
`;

const BlogDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  &:hover {
    .gatsby-image-wrapper {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
    }
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    color: #242d41;
    text-align: left;
    margin-top: 15px;
  }
  @media screen and (max-width: 991px) {
    h3 {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 768px) {
    h3 {
      font-size: 16px;
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }
`;

const Index = (props) => {
  const { mainImage, videoImage } = props.data;
  const [paneChoice, setPaneChoice] = useState("operators");
  const videoLinkArray = ["418135852"];
  const headerArray = ["Unparalleled safety and efficiency. As standard."];
  const subHeaderArray = [
    "Save time and reduce costs with our intuitive tools, real-time data, and trusted support",
  ];
  const linkText = ["Get in touch"];
  const linkArray = ["/contact/"];
  const videoPictureArray = [
    videoImage?.childImageSharp.fluid,
    mainImage?.childImageSharp.fluid,
  ];

  return (
    <Layout>
      <Seo
        title="The New Standard in Aviation Safety & Efficiency"
        description="We are building the tools and services to enable the next generation of Digital Aviation Records"
      />
      <PageHeaderVideo
        header={headerArray[0]}
        subHeader={subHeaderArray[0]}
        linkText={linkText[0]}
        link={linkArray[0]}
        bigHeader
        bigImage={videoPictureArray}
        useBgImage
        videoLink={videoLinkArray[0]}
        videoCaroselNumber={0}
      />
      <TrustedBy />
      <Container>
        <Row className="justify-content-md-center">
          <Col xl={8} lg={10} md={10}>
            <TransformHeader>
              Harness the power of your data with our intuitive tools, connected
              workflows and real-time insights
            </TransformHeader>
          </Col>
          <Col xl={10} lg={12} md={12}>
            <Row className={styles.tabsRow}>
              <Col md={3}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeIn"
                  offset={0}
                >
                  <ClickableOption
                    selected={paneChoice === "operators"}
                    onClick={() => setPaneChoice("operators")}
                  >
                    Airlines and Operators
                  </ClickableOption>
                </AnimationOnScroll>
              </Col>
              <Col md={2}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeIn"
                  offset={0}
                  delay={100}
                >
                  <ClickableOption
                    selected={paneChoice === "mro"}
                    onClick={() => setPaneChoice("mro")}
                  >
                    MRO
                  </ClickableOption>
                </AnimationOnScroll>
              </Col>
              <Col md={2}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeIn"
                  offset={0}
                  delay={200}
                >
                  <ClickableOption
                    selected={paneChoice === "regulators"}
                    onClick={() => setPaneChoice("regulators")}
                  >
                    Regulators
                  </ClickableOption>
                </AnimationOnScroll>
              </Col>
              <Col md={3}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeIn"
                  offset={0}
                  delay={200}
                >
                  <ClickableOption
                    selected={paneChoice === "training"}
                    onClick={() => setPaneChoice("training")}
                  >
                    Training Organisations
                  </ClickableOption>
                </AnimationOnScroll>
              </Col>
              <Col md={2}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeIn"
                  offset={0}
                  delay={200}
                >
                  <ClickableOption
                    selected={paneChoice === "airports"}
                    onClick={() => setPaneChoice("airports")}
                  >
                    Airports
                  </ClickableOption>
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <TabSection>
        <Container>
          <Row className="justify-content-md-center">
            <Col xl={10} lg={10} md={10}>
              <DisplayOption choice={paneChoice} />
            </Col>
          </Row>
        </Container>
      </TabSection>
      <AnimationOnScroll
        animateOnce
        animateIn="animate__fadeIn"
        offset={0}
        delay={300}
      >
        <Authorities />
      </AnimationOnScroll>
      <AnimationOnScroll
        animateOnce
        animateIn="animate__fadeIn"
        offset={0}
        delay={300}
      >
        <GlobalScale />
      </AnimationOnScroll>
      <AnimationOnScroll
        animateOnce
        animateIn="animate__fadeIn"
        offset={0}
        delay={300}
      >
        <MeetTheTeam />
      </AnimationOnScroll>
      <Container>
        <Row className={`justify-content-md-center ${styles.blogPostsParent}`}>
          <Col md={12}>
            <Row>
              {props.data.allMarkdownRemark.edges.map((edge, key) => (
                <Col key={key} md={3} sm={6}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeIn"
                    offset={0}
                    delay={key * 150}
                  >
                    <BlogDiv>
                      <Link
                        to={edge.node.frontmatter.slug}
                        className={styles.fullLink}
                      />
                      {edge.node.frontmatter.squareImage ||
                      edge.node.frontmatter.featuredImage ? (
                        <Fragment>
                          <Img
                            fluid={
                              edge.node.frontmatter.squareImage
                                ? edge.node.frontmatter.squareImage
                                    .childImageSharp.fluid
                                : edge.node.frontmatter.featuredImage
                                    .childImageSharp.fluid
                            }
                            className={styles.blogFeaturedImage}
                          />
                          <Img
                            fluid={
                              edge.node.frontmatter.featuredImage
                                ? edge.node.frontmatter.featuredImage
                                    .childImageSharp.fluid
                                : edge.node.frontmatter.squareImage
                                    .childImageSharp.fluid
                            }
                            className={styles.blogFeaturedImageFullHD}
                          />
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Img
                            fluid={props.data.blogDefault.childImageSharp.fluid}
                            className={styles.blogFeaturedImage}
                          />
                          <Img
                            fluid={
                              props.data.blogDefaultFullHD.childImageSharp.fluid
                            }
                            className={styles.blogFeaturedImageFullHD}
                          />
                        </Fragment>
                      )}
                      <h3>{edge.node.frontmatter.title}</h3>
                    </BlogDiv>
                  </AnimationOnScroll>
                </Col>
              ))}
            </Row>
            <Started />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query IndexQuery {
    mainImage: file(relativePath: { eq: "trustflight-featured.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    videoImage: file(relativePath: { eq: "tech-log2x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 526) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jarImage: file(relativePath: { eq: "jar-featured.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 526) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blogDefault: file(relativePath: { eq: "Blog/blog_default_square.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 512) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blogDefaultFullHD: file(
      relativePath: { eq: "Blog/blog_default_fullhd.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 512) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(
      filter: {
        fields: { sourceInstanceName: { eq: "news" } }
        frontmatter: { tags: { ne: "Article" } }
      }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 4
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            squareImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
    }
  }
`;

export default Index;
