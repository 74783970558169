import React from "react";
import styled from "styled-components";
import { AnimationOnScroll } from "react-animation-on-scroll";
import TechUser from "../../assets/Landing/techlog-user.jpg";
import MELUser from "../../assets/Landing/mel-user.jpg";
import CentrikUser from "../../assets/Landing/centrik-user.jpg";
import MELUserMale from "../../assets/Landing/mel-user-male.jpg";
import CentrikUserRisk from "../../assets/Landing/user-centrik-risk.jpg";
import CentrikUserReporting from "../../assets/Landing/user-centrik-reporting.jpg";
import CentrikUserSafety from "../../assets/Landing/user-centrik-safety.jpg";
import CentrikUserDashboard from "../../assets/Landing/user-centrik-dashboard.jpg";
import Services from "../../assets/Landing/services.jpg";
import NewDisplayCard from "./newDisplayCard";

const OptionDiv = styled.div`
  font-family: "Open Sans";
`;

const OptionHeading = styled.div`
  padding-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.03px;
  text-align: center;
  color: #242d41;
  @media screen and (max-width: 1200px) {
    font-size: 20px;
    padding-bottom: 0;
  }
  @media screen and (max-width: 576px) {
    font-size: 18px;
    padding-bottom: 0;
  }
`;

const TransformHeader = styled.h2`
  margin-top: 80px;
  margin-bottom: 80px;
  font-weight: 600;
  letter-spacing: 0.04px;
  line-height: 48px;
  text-align: center;
  color: #242d41;
  /* margin-bottom: 25px; */
  @media screen and (max-width: 1200px) {
    font-size: 28px;
    line-height: 36px;
  }
  @media screen and (max-width: 991px) {
    font-size: 28px;
    margin-top: 60px;
    margin-bottom: 60px;
    line-height: inherit;
  }
  @media screen and (max-width: 576px) {
    font-size: 22px;
    margin-top: 40px;
    margin-bottom: 40px;
    line-height: inherit;
  }
`;

const OptionText = styled.div`
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: 0.03px;
  text-align: center;
  color: rgba(36, 45, 65, 0.7);
  padding: 0 40px;
  margin-bottom: 40px;
  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }
  @media screen and (max-width: 576px) {
    font-size: 14px;
    padding: 0;
  }
`;

const ServicesDiv = styled.div`
  margin-top: 80px;
  margin-bottom: 60px;
  @media screen and (max-width: 991px) {
    margin-top: 20px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 576px) {
    margin-top: 20px;
    margin-bottom: 40px;
  }
`;

const DisplayCardsDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
`;

const TechCard = {
  img: TechUser,
  title: "Tech Log",
  text: "Intuitive workflows, intelligent validations, and powerful integrations ensure compliance without the hassle. Gain a real-time view of your aircraft’s status, reduce costs and prevent delays.",
  ticks: ["Reduce cost", "Gain real-time data", "Increase compliance"],
  link: "/techlog/",
  buttonText: "Discover Tech Log",
};

const TechCardMro = {
  img: TechUser,
  title: "Tech Log",
  text: "Gain an instant and comprehensive overview of the status of aircraft entering maintenance, paired with digital releases and powerful integrations to eliminate uncertainty and duplicated effort.",
  ticks: [
    "Reduce aircraft downtime",
    "Eliminate duplication",
    "Increase awareness",
  ],
  link: "/techlog/",
  buttonText: "Discover Tech Log",
};

const ServicesCard = {
  img: Services,
  title: "Aviation Services",
  text: "Our experts work in concert with your operational teams to leverage all the benefits of digital and optimise your operation from pushback to cruise and beyond.",
  ticks: [
    "AOC Startup Service",
    "Document Publishing",
    "Fleet Asset Management",
    "Continuing Airworthiness Management",
  ],
  link: "/services/",
  buttonText: "Discover more",
};

const MelCardAirline = {
  img: MELUserMale,
  title: "MEL Manager",
  text: "Issue and revise MELs in a fraction of the time using a simple but powerful MEL management platform. Designed from the ground up to simplify the task of MEL management, the system saves countless hours of administrative work.",
  ticks: [
    "Guaranteed compliance with revisions",
    "Facilitates fully digital workflows in our Tech Log",
    "Up to 6x faster to create a new MEL",
  ],
  link: "/mel-manager/",
  buttonText: "Discover MEL Manager",
};

const MelCardRegulator = {
  img: MELUser,
  title: "MEL Manager",
  text: "Issue and revise MELs in a fraction of the time using a simple but powerful MEL management platform. Designed from the ground up to simplify the task of MEL management, the system saves countless hours of administrative work.",
  ticks: [
    "Guaranteed compliance with revisions",
    "Facilitates fully digital workflows in our Tech Log",
    "Up to 6x faster to create a new MEL",
  ],
  link: "/mel-manager/",
  buttonText: "Discover MEL Manager",
};

const CentrikCard = {
  img: CentrikUserDashboard,
  title: "SMS/QMS",
  text: "A simple, intuitive operational management system incorporating a dynamic suite of interactive modules; delivering effortless compliance, real-time single source of truth data and robust action management. Accessible on any device or browser both online and offline.",
  ticks: [
    "Enhance safety/SMS",
    "Increase efficiency",
    "Achieve total operational oversight",
  ],
  link: "/centrik/",
  buttonText: "Discover SMS/QMS",
};

const CentrikCardMro = {
  img: CentrikUserRisk,
  title: "SMS/QMS",
  text: "Complete operational maintenance management from reporting to authorisations, documents to risk. Centrik streamlines and simplifies them all. Reduce duplication by eradicating the dysfunctional processes associated with paper and spreadsheet based operations.",
  ticks: ["Enhance SMS/QMS", "Simple & intuitive", "Built-in asset management"],
  link: "/centrik/",
  buttonText: "Discover SMS/QMS",
};

const CentrikCardRegulators = {
  img: CentrikUserSafety,
  title: "SMS/QMS",
  text: "Enhanced oversight of organisations, digitised applications and embedded regulations to revolutionise your operation. A holistic solution to streamline and improve your entire regulatory process, manage every aspect of your external oversight as well as your internal compliance.  It offers instant access to embedded regulations, constantly monitored and updated by the Centrik team.",
  ticks: [
    "Digital applications",
    "Enhanced organisational oversight",
    "Advanced analysis of MORs",
  ],
  link: "/centrik/",
  buttonText: "Discover SMS/QMS",
};

const CentrikCardTraining = {
  img: CentrikUser,
  title: "SMS/QMS",
  text: "A digital training and reporting solution to simplify all aspects of ATO management from onboarding students to e-training forms, grading, stats, sim sessions, line training and beyond. In the office, hangar, cockpit or simulator, you have your entire organisational training currency, SMS & QMS  at your fingertips.",
  ticks: [
    "Delivers a paperless, digital training solution",
    "Global oversight of all organisational training",
    "Student management functionality",
  ],
  link: "/centrik/",
  buttonText: "Discover SMS/QMS",
};

const CentrikCardAirports = {
  img: CentrikUserReporting,
  title: "SMS/QMS",
  text: "Complete operational oversight of your airport, interactive modules deliver enhanced safety, risk, compliance and training workflows through one system.",
  ticks: [
    "Pro-active risk management",
    "Enhanced SMS/QMS performance",
    "Tenant/third party management",
  ],
  link: "/centrik/",
  buttonText: "Discover SMS/QMS",
};

const headingTexts = {
  operators: {
    heading:
      "A full suite of products, designed to transition you to paperless workflows",
    text: "",
  },
  mro: {
    heading: "Achieve new levels of productivity, quality, and safety",
    text: "",
  },
  regulators: {
    heading:
      "Introducing a new era of oversight through real-time data, with compliance and safety built right in",
    text: "",
  },
  training: {
    heading:
      "Your entire organisational training currency and records at your fingertips",
    text: "",
  },
  airports: {
    heading:
      "Simplify and streamline airport operations from documents to security",
    text: "",
  },
};

const DisplayOption = ({ choice }) => {
  let cardsArray = [];
  switch (choice) {
    case "operators":
      cardsArray = [TechCard, CentrikCard, MelCardAirline];
      break;
    case "mro":
      cardsArray = [CentrikCardMro, TechCardMro];
      break;
    case "regulators":
      cardsArray = [CentrikCardRegulators, MelCardRegulator];
      break;
    case "training":
      cardsArray = [CentrikCardTraining];
      break;
    case "airports":
      cardsArray = [CentrikCardAirports];
      break;
    default:
      cardsArray = [];
  }

  const cards = cardsArray.map((card, key) => (
    <AnimationOnScroll
      key={key}
      animateOnce
      animateIn="animate__fadeIn"
      delay={key * 100}
      offset={0}
    >
      <NewDisplayCard
        key={card.title}
        img={card.img}
        title={card.title}
        text={card.text}
        ticks={card.ticks}
        link={card.link}
        buttonText={card.buttonText}
        index={key}
      />
    </AnimationOnScroll>
  ));

  return (
    <OptionDiv>
      <div>
        <AnimationOnScroll
          animateOnce
          animateIn="animate__fadeIn"
          offset={0}
          delay={200}
        >
          <OptionHeading>{headingTexts[choice].heading}</OptionHeading>
          <OptionText>{headingTexts[choice].text}</OptionText>
        </AnimationOnScroll>
        <DisplayCardsDiv>{cards}</DisplayCardsDiv>
        <ServicesDiv>
          <TransformHeader>
            Digital leaders in aviation services that allow you to achieve far
            more with far less
          </TransformHeader>
          <NewDisplayCard
            key={ServicesCard.title}
            img={ServicesCard.img}
            title={ServicesCard.title}
            text={ServicesCard.text}
            ticks={ServicesCard.ticks}
            link={ServicesCard.link}
            buttonText={ServicesCard.buttonText}
            index={0}
          />
        </ServicesDiv>
      </div>
    </OptionDiv>
  );
};

export default DisplayOption;
