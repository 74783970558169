import React from "react";
import styled from "styled-components";
import { AnimationOnScroll } from "react-animation-on-scroll";
import BirminghamAirport from "../../assets/client-bhx.svg";
import Boeing from "../../assets/client-boeing.svg";
import Cae from "../../assets/client-cae.svg";
import Flair from "../../assets/client-flair.svg";
import Tag from "../../assets/client-tag.svg";
import Lux from "../../assets/client-lux.svg";
import * as styles from "./trustedBy.module.less";

const operators = [Boeing, Cae, Lux, Tag, Flair, BirminghamAirport];

const operatorImages = operators.map((operator, key) => (
  <AnimationOnScroll
    key={operator}
    animateOnce
    animateIn="animate__fadeInUp"
    delay={key * 75}
    offset={0}
  >
    <img src={operator} alt={`${operator}`} />
  </AnimationOnScroll>
));

const OperatorDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-image: linear-gradient(to top, #f8fcff, var(--white));
  padding: 60px 0;
  @media screen and (max-width: 991px) {
    padding: 20px 0;
    flex-wrap: wrap;
    gap: 1rem 0rem;
  }
  @media screen and (max-width: 576px) {
    h4 {
      display: none;
    }
  }
`;

const TrustedBy = () => (
  <OperatorDiv className={styles.operatorsParent}>
    {/* <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft" offset={0}>
    </AnimationOnScroll> */}
    {operatorImages}
  </OperatorDiv>
);

export default TrustedBy;
