import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import styled from "styled-components";
import GoArrow from "../../assets/icon-arrow-white.svg";
import GreenTick from "../../assets/icon-tick.svg";
import * as styles from "./newDisplayCard.module.less";

const StyledImage = styled.img`
  width: 100%;
  max-height: 100%;
  transition: all 0.3s;
  @media screen and (max-width: 768px) {
    max-height: 130px;
  }
  @media screen and (max-width: 576px) {
    margin-bottom: 15px;
    max-height: 80px;
  }
`;

const ArrowImage = styled.img`
  height: 13px;
  ${(props) => props.small && "height: 13px;"}
  margin-left: 10px;
  margin-top: -2px;
  transition: all 0.4s;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: rgba(36, 45, 65, 0.9);
  margin-top: 8px;
  transition: all 0.4s;
  ${(props) => props.small && "font-size: 12px;"}
  @media screen and (max-width: 991px) {
    font-size: 13px;
  }
`;

const DisplayCardDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: white;
    ${(props) => (props.ticks ? "width: 80%" : "width: 100%")};
    height: 100%;
    min-height: 374px;
    position: relative;
    box-shadow: 0 6px 20px 0 rgba(196, 200, 209, 0.64);
    border-radius: 19px;
    ${(props) => props.noShadow && "box-shadow: none;"}
    ${(props) => (props.noShadow ? "padding: 10px 0 10px 0;" : "padding: 0px;")}
    ${(props) => (props.noShadow ? "margin: 0;" : "margin: 2px 0;")}
    ${(props) =>
      props.bottomPage &&
      "box-shadow: 0 7px 19px 0 rgba(196, 200, 209, 0.64); padding: 30px 25px; margin: 0;"}
    ${(props) => props.padding && "padding-bottom: 30px;"}
    &:hover ${StyledImage} {
        transform: scale(1.02);
    }
    &:hover ${ArrowImage} {
        transform: translateX(3px);
    }
    &:hover ${Text} {
        color: #666;
    }
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #242d41;
  ${(props) => props.small && "font-size: 12px;"}
  @media screen and (max-width: 1200px) {
    font-size: 16px;
    ${(props) => props.small && "font-size: 12px;"}
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    display: block;
  }
  @media screen and (max-width: 576px) {
    text-align: center;
  }
`;

const TickDiv = styled.div`
  font-size: 14px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  ${(props) => (props.display ? "display: block:" : "display: none;")};
  @media screen and (max-width: 991px) {
    padding-left: 15xpx;
  }
  @media screen and (max-width: 768px) {
    padding-left: 0;
    border-left: 0;
    padding-top: 12px;
  }
  @media screen and (max-width: 450px) {
    border-top: 1px solid #f0f5f9;
  }
`;

const StyledTickText = styled.span`
  font-size: 16px;
  line-height: 1.77;
  letter-spacing: 0.02px;
  color: rgba(36, 45, 65, 0.8);
  text-align: left;
  padding-left: 8px;
  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
`;

const InnerTickDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  margin: 5px 0;
`;

const TickImage = styled.img`
  padding-top: 4px;
  width: 20px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 1rem;
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

const ImageDiv = styled.div`
  flex: 1;
  ${(props) =>
    props.leftImage
      ? "border-radius: 19px 0 0 19px;"
      : "border-radius: 0 19px 19px 0;"};
  min-height: 374px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 991px) {
    ${(props) =>
      props.leftImage
        ? "border-radius: 19px 19px 0 0;;"
        : "border-radius: 0 0 19px 19px;"};
  }
`;

const ActionButton = styled.button`
  height: 40px;
  border-radius: 21px;
  border: solid 2px #1e5bb5;
  background-color: #1e5bb5;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 24px;
  line-height: 13px;
  &:hover {
    background-color: #194d9a;
    border-color: #194d9a;
    transition: all 0.6s;
  }
  @media screen and (max-width: 576px) {
    font-size: 12px;
  }
`;

const ButtonDiv = styled.div`
  margin-top: 28px;
`;

const CardDiv = styled.div`
  flex: 1;
  text-align: left;
`;

const PaddingDiv = styled.div`
  padding: 32px 28px;
  @media screen and (max-width: 991px) {
    padding: 12px 28px 32px 28px;
  }
  @media screen and (max-width: 576px) {
    padding: 12px 28px 32px 28px;
  }
`;

const DisplayCard = (props) => {
  const ticktext = props.ticks
    ? props.ticks.map((tick) => {
        return (
          <InnerTickDiv key={tick}>
            <TickImage src={GreenTick} alt="tick" />
            <StyledTickText>{tick}</StyledTickText>
          </InnerTickDiv>
        );
      })
    : null;
  const leftImage = props.index % 2 === 0;
  const imageSection = (
    <ImageDiv
      src={props.img}
      leftImage={leftImage}
      className={styles.displayCardCol}
    />
  );
  const link = props.link.replace(/^\/?(.*?)\/?$/, "$1");
  return (
    <DisplayCardDiv
      display={props.ticks}
      noShadow={props.noShadow}
      bottomPage={props.bottomPage}
      padding={props.padding}
    >
      <Link to={`/${link}/`} className={styles.fullLink} />
      <CardWrapper>
        {leftImage && imageSection}
        <CardDiv className={styles.desktopText}>
          <PaddingDiv>
            <Title small={props.small}>{props.title} </Title>
            <Row className={styles.mobileText}>
              <Col sm={5}>
                <StyledImage src={props.img} alt="icon" />
              </Col>
              <Col sm={7}>
                <Text small={props.small}>{props.text}</Text>
                <TickDiv display={props.ticks}>{ticktext}</TickDiv>
              </Col>
            </Row>
            <Text small={props.small} className={styles.desktopText}>
              {props.text}
            </Text>
            <TickDiv display={props.ticks}>{ticktext}</TickDiv>
            <ButtonDiv>
              <ActionButton>
                {props.buttonText}
                <ArrowImage src={GoArrow} alt="arrow" small={props.small} />
              </ActionButton>
            </ButtonDiv>
          </PaddingDiv>
        </CardDiv>
        {!leftImage && imageSection}
      </CardWrapper>
    </DisplayCardDiv>
  );
};

export default DisplayCard;
