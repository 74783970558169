import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Container } from "react-bootstrap";
import GoArrow from "../../assets/icon-arrow-white.svg";
import Aviation from "../../assets/Landing/icon-aviation-experts.svg";
import Software from "../../assets/Landing/icon-software-experts.svg";

const Background = styled.div`
  background-image: linear-gradient(to top, #f8fcff, #ffffff);
`;

const Header = styled.h2`
  grid-column: 1 / 5;
  grid-row: 1 / 2;
  @media screen and (max-width: 991px) {
    grid-column: 1/2;
    grid-row: 1/2;
    font-size: 28px;
  }
  @media screen and (max-width: 576px) {
    font-size: 22px;
  }
`;

const Padding = styled.div`
  padding: 80px 0px;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  gap: 1rem;
  @media screen and (max-width: 1200px) {
    padding: 60px 0;
  }
  @media screen and (max-width: 991px) {
    padding: 40px 0;
    grid-template-columns: 1fr;
  }
`;

const Section = styled.div`
  grid-row: 2 / span 3;
  @media screen and (max-width: 991px) {
    grid-column: 1/2;
    grid-row: initial;
    text-align: center;
  }
`;

const ExplanationText = styled.p`
  font-size: 18px;
  line-height: 1.56;
  color: rgba(36, 45, 65, 0.9);
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  padding-right: 74px;
  @media screen and (max-width: 991px) {
    grid-column: 1/2;
    grid-row: 2/3;
    padding-right: 0;
    font-size: 16px;
  }
`;

const SectionHeading = styled.h3`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #1e5bb5;
  margin-top: 24px;
`;

const SectionText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: rgba(36, 45, 65, 0.9);
`;

const ButtonWrapper = styled.div`
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  @media screen and (max-width: 991px) {
    grid-column: 1/2;
    grid-row: 6/7;
    text-align: center;
  }
`;

const ActionButton = styled.button`
  height: 40px;
  border-radius: 21px;
  border: solid 2px #1e5bb5;
  background-color: #1e5bb5;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 24px;
  line-height: 13px;
  &:hover {
    background-color: #194D9A;
    border-color: #194D9A;
    transition: all 0.4s;
  }
`;

const MeetTheTeam = () => {
  const ArrowImage = styled.img`
    height: 13px;
    ${(props) => props.small && "height: 13px;"}
    margin-left: 10px;
    margin-top: -2px;
    transition: all 0.4s;
  `;

  const sections = [
    {
      img: Aviation,
      title: "100+ aviation experts",
      text:
        "Supporting our customers with airworthiness, auditing, compliance and technical authoring.",
    },
    {
      img: Software,
      title: "50+ software experts",
      text:
        "Highly skilled in development, design and operations, our team are building the next generation of aviation software.",
    },
  ];
  return (
    <Background>
      <Container>
        <Padding>
          <Header>Created and led by industy experts</Header>
          <ExplanationText>
            Core to TrustFlight is a passionate team of technical aviation and
            software leaders, whose collective industry experience and
            creativity enable us to create best-in-class tools and services that
            support safety and efficiency for all of our customers.
          </ExplanationText>
          <ButtonWrapper>
            <Link to="/about/">
              <ActionButton>
                Learn more about us
                <ArrowImage src={GoArrow} alt="arrow" />
              </ActionButton>
            </Link>
          </ButtonWrapper>
          {sections.map((section, index) => (
            <Section key={index}>
              <img src={section.img} alt="arrow" />
              <SectionHeading>{section.title}</SectionHeading>
              <SectionText>{section.text}</SectionText>
            </Section>
          ))}
        </Padding>
      </Container>
    </Background>
  );
};

export default MeetTheTeam;
