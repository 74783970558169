// extracted by mini-css-extract-plugin
export var actionLink = "pageHeaderVideo-module--actionLink--554e8";
export var actionLinkHolder = "pageHeaderVideo-module--actionLinkHolder--90d83";
export var actionLinkWhite = "pageHeaderVideo-module--actionLinkWhite--c7118";
export var arrowBox = "pageHeaderVideo-module--arrowBox--889f1";
export var arrowBoxOpacity = "pageHeaderVideo-module--arrowBoxOpacity--fae27";
export var bigHeader = "pageHeaderVideo-module--bigHeader--aeb5e";
export var bigPictureLink = "pageHeaderVideo-module--bigPictureLink--68fa2";
export var buttonPlay = "pageHeaderVideo-module--buttonPlay--221c6";
export var buttonWrapper = "pageHeaderVideo-module--buttonWrapper--f726f";
export var desktop = "pageHeaderVideo-module--desktop--85ab8";
export var flexParent = "pageHeaderVideo-module--flexParent--a7454";
export var header = "pageHeaderVideo-module--header--93f8f";
export var headerDiv = "pageHeaderVideo-module--headerDiv--fe485";
export var headerImage = "pageHeaderVideo-module--headerImage--898b1";
export var headerTag = "pageHeaderVideo-module--headerTag--d040b";
export var headerTagNormal = "pageHeaderVideo-module--headerTagNormal--5a361";
export var headerTagStrong = "pageHeaderVideo-module--headerTagStrong--039b0";
export var headerTextCol = "pageHeaderVideo-module--headerTextCol--d34f4";
export var imageWithShadow = "pageHeaderVideo-module--imageWithShadow--58724";
export var leftButtonWrapper = "pageHeaderVideo-module--leftButtonWrapper--4a702";
export var longTag = "pageHeaderVideo-module--longTag--386bd";
export var mobile = "pageHeaderVideo-module--mobile--411e1";
export var modalVideo = "pageHeaderVideo-module--modal-video--56c59";
export var modalVideoInner = "pageHeaderVideo-module--modal-video-inner--70d91";
export var pageHeader = "pageHeaderVideo-module--pageHeader--8cab6";
export var pageHeaderBg = "pageHeaderVideo-module--pageHeaderBg--5379b";
export var pageHeaderMedium = "pageHeaderVideo-module--pageHeaderMedium--0f752";
export var playIcon = "pageHeaderVideo-module--playIcon--2392a";
export var rightButtonWrapper = "pageHeaderVideo-module--rightButtonWrapper--f44ae";
export var subHeader = "pageHeaderVideo-module--subHeader--32050";
export var textWrapper = "pageHeaderVideo-module--textWrapper--b2c94";
export var videoWrapper = "pageHeaderVideo-module--videoWrapper--294ab";